import React from 'react'
import styled from 'styled-components'
import { Flex, FlexProps } from '../../../atoms'

export const ShareIconFlex: React.FC<FlexProps> = styled(Flex)<FlexProps>`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: background-color 0.25s ease-out;
  color: ${({ theme }) => theme.colors.purpleBlue};
  background-color: ${({ theme }) => theme.colors.purpleBlueLight};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.purpleBlue};
  }
`
