import React from 'react'
import { LinkedinShareButton, TwitterShareButton } from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { ShareIconFlex } from './ShareBar.styled'
import { Flex, Box, Icons } from '../../../atoms'

interface ShareBarProps {
  urlToShare: string
  direction: 'row' | 'column'
  color?: string
}

const ShareBar: React.FC<ShareBarProps> = ({ urlToShare, direction = 'row' }) => {
  return (
    <Flex flexDirection={direction} alignItems={'center'}>
      <Box flex={'0 0 auto'} mb={direction === 'column' ? 4 : 0} mr={direction === 'row' ? 4 : 0}>
        <CopyToClipboard text={urlToShare}>
          <Box clickable>
            <ShareIconFlex>
              <Icons.Mail />
            </ShareIconFlex>
          </Box>
        </CopyToClipboard>
      </Box>
      <Box flex={'0 0 auto'} mb={direction === 'column' ? 4 : 0} mr={direction === 'row' ? 4 : 0}>
        <TwitterShareButton url={urlToShare} style={{ outline: 'none' }}>
          <ShareIconFlex>
            <Icons.Twitter />
          </ShareIconFlex>
        </TwitterShareButton>
      </Box>
      <Box flex={'0 0 auto'}>
        <LinkedinShareButton url={urlToShare} style={{ outline: 'none' }}>
          <ShareIconFlex>
            <Icons.Linkedin />
          </ShareIconFlex>
        </LinkedinShareButton>
      </Box>
    </Flex>
  )
}

export default ShareBar
